@use "sass:math";

.local-BrShowSm {
  display: inline;

  @include mq(sm) {
    display: inline !important;
  }

  @include mq(md) {
    display: none !important;
  }

  @include mq(lg) {
    display: none;
  }

  @include mq(xl) {
    display: none;
  }
}

.local-FooterSearchMenu {
  padding-top: rem(32);
  background-color: $color-bg-area;

  @include mq(md) {
    padding: rem(36) rem(25);
  }

  @include mq(lg) {
    padding: rem(48) rem(25) rem(40);
  }

  @include mq(xl) {
    padding: rem(48) rem(25) rem(34);
  }
}

.local-FooterSearchMenu_Title {
  font-size: rem(18);
  font-weight: 700;
  line-height: rem(27);
  text-align: center;

  @include mq(lg) {
    font-size: rem(23);
    line-height: rem(34.5);
  }
}

.local-FooterSearchMenu_Lead {
  padding: 0 rem(20);
  margin-top: rem(8);
  font-size: rem(14);
  font-weight: 400;
  line-height: rem(25.2);
  text-align: center;

  @include mq(lg) {
    padding: 0;
    font-size: rem(16);
    line-height: rem(28.8);
  }
}

.local-FooterSearchMenu_Sub {
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: rem(32);
  margin-bottom: rem(-1);
  border-top: 1px solid $color-border;

  @include mq(md) {
    justify-content: center;
    margin-bottom: rem(0);
    border-top: none;
  }

  @include mq(lg) {
    margin-top: rem(40);
  }
}

.local-FooterSearchMenu_SubItem {
  flex-basis: calc(math.percentage(math.div(6, 12)));
  align-self: stretch;
  max-width: calc(math.percentage(math.div(6, 12)));

  @include mq(md) {
    flex-basis: auto;
    max-width: none;
  }

  &:nth-child(even) {
    a {
      border-right: none;

      @include mq(md) {
        border-right: 1px solid $color-border;
      }
    }
  }

  &:last-child {
    a {
      @include mq(md) {
        border-right: none;
      }
    }
  }

  a {
    display: block;
    padding: rem(15);
    font-size: rem(12);
    font-weight: 700;
    line-height: rem(16.5);
    color: $color-black;
    text-align: left;
    text-decoration: none;
    border-right: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;

    @include mq(md) {
      padding: rem(11) rem(15);
      font-size: rem(13);
      line-height: rem(19.5);
      border-bottom: none;
    }

    @include mq(lg) {
      padding: rem(9.5) rem(16);
      font-size: rem(16);
      line-height: rem(25.5);
    }

    @include mq(xl) {
      padding: rem(8.5) rem(32);
    }

    @include hover-without-touch {
      color: $color-accent;
    }
  }

  svg {
    width: rem(16);
    height: rem(16);
    margin-right: rem(10);
    margin-bottom: rem(2);
    vertical-align: middle;
    fill: $color-accent;

    @include mq(md) {
      width: rem(15);
      height: rem(15);
      margin-right: rem(8);
    }

    @include mq(lg) {
      width: rem(17);
      height: rem(17);
      margin-right: rem(10);
      margin-bottom: rem(4);
    }
  }
}

.local-FooterSearchMenu_PageTop {
  display: none;

  @include mq(md) {
    display: none !important;
  }

  a {
    position: fixed;
    right: 10px;
    bottom: 20px;
    display: block;
    width: 44px;
    height: 44px;
    padding: 0;
    background-color: rgb(129 129 129 / 70%);
    border-radius: 50%;

    span {
      font-size: 24px;
      line-height: 38px;
    }
  }
}

@use "sass:math";

html {
  &.-search-open {
    width: 100%;
    height: 100dvh;
    overflow: hidden;

    @include mq(lg) {
      overflow: visible;
    }

    body {
      width: 100%;
      height: 100dvh;
      overflow: hidden;

      @include mq(lg) {
        overflow: visible;
      }

      .global-Header_MainInner {
        @include mq(lg) {
          position: relative;

          &::after {
            position: absolute;
            bottom: rem(-8);
            left: 0;
            z-index: 133;
            display: block;
            width: 100%;
            height: rem(8);
            content: "";
            background: linear-gradient(
              180deg,
              rgb(102 102 102 / 15%) 0%,
              rgb(102 102 102 / 0%) 100%
            );
          }
        }
      }

      .global-Header_MainContentsWrap {
        @include mq(lg) {
          border-bottom: rem(1) solid $color-white !important;
        }
      }

      .global-Header_SubMenu {
      }

      .global-Header_BtnSearch {
        &::before {
          background-image: $svg-x-black;

          @include mq(lg) {
            background-image: none;
          }
        }

        &::after {
          @include mq(lg) {
            opacity: 1;
          }
        }

        & > span {
          position: absolute;
          top: auto;
          bottom: rem(-24);
          left: 50%;
          display: block;
          width: 120%;
          height: rem(2);
          margin-left: -60%;
          background-color: $color-accent;
          animation: animation-borderwide 0.5s forwards;
        }

        @include hover-without-touch {
          @include mq(lg) {
            &::before {
              opacity: 1;
            }
          }
        }

        @include mq(lg) {
          pointer-events: none;
        }
      }

      .global-Pagetop {
        @include mq(lg) {
          display: none !important;
        }
      }
    }
  }

  &.-menu-open {
    width: 100%;
    height: 100dvh;
    overflow: hidden;

    @include mq(lg) {
      overflow: visible;
    }

    body {
      width: 100%;
      height: 100dvh;
      overflow: hidden;

      @include mq(lg) {
        overflow: visible;
      }

      .global-Header_BtnMenu {
        &::before {
          background-image: $svg-x-black;
        }
      }

      .global-Pagetop {
        @include mq(lg) {
          display: none !important;
        }
      }
    }
  }

  &.-main-menu-open {
    width: 100%;
    height: 100dvh;
    overflow: hidden;

    @include mq(lg) {
      overflow: visible;
    }

    body {
      width: 100%;
      height: 100dvh;
      overflow: hidden;

      @include mq(lg) {
        overflow: visible;
      }
    }
  }

  &.-sub-menu-open {
    width: 100%;
    height: 100dvh;
    overflow: hidden;

    @include mq(lg) {
      overflow: visible;
    }

    body {
      width: 100%;
      height: 100dvh;
      overflow: hidden;

      @include mq(lg) {
        overflow: visible;
      }

      .global-Header_MainInner {
        @include mq(lg) {
          position: relative;

          &::after {
            position: absolute;
            bottom: rem(-8);
            left: 0;
            z-index: 133;
            display: block;
            width: 100%;
            height: rem(8);
            content: "";
            background: linear-gradient(
              180deg,
              rgb(102 102 102 / 15%) 0%,
              rgb(102 102 102 / 0%) 100%
            );
          }
        }
      }

      .global-Header_MainContentsWrap {
        @include mq(lg) {
          border-bottom: rem(1) solid $color-white !important;
        }
      }

      .global-Pagetop {
        @include mq(lg) {
          display: none !important;
        }
      }
    }
  }
}

body {
  &.-global-menu-open {
    .global-Header_GlobalMenuTitle {
      span {
        &::after {
          transform: translate(0, -50%) rotate(180deg);
        }
      }

      &::before {
        @include mq(lg) {
          background-image: $svg-global-accent;
        }
      }

      & > span {
        @include mq(lg) {
          position: absolute;
          top: auto;
          bottom: rem(-25);
          left: 50%;
          display: block;
          width: 120%;
          height: rem(2);
          margin-left: -60%;
          text-indent: -9999px;
          background-color: $color-accent;
          animation: animation-borderwide 0.5s forwards;
        }

        &::after {
          @include mq(lg) {
            display: none;
          }
        }
      }
    }
  }

  &.-global-header-follow {
    .global-Header {
      padding-top: rem(52);

      @include mq(lg) {
        padding-top: rem(73);
      }
    }

    .global-Header_Inner {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 132;
      width: 100%;
    }

    &.-local-mainnavi-follow {
      .global-Header {
        padding-top: rem(0);

        @include mq(lg) {
          padding-top: rem(0);
        }
      }

      .global-Header_Inner {
        position: static;
      }
    }

    &.-is-scroll {
      .global-Header_MainInner {
        @include mq(lg) {
          position: relative;

          &::after {
            position: absolute;
            bottom: rem(-8);
            left: 0;
            z-index: 133;
            display: block;
            width: 100%;
            height: rem(8);
            content: "";
            background: linear-gradient(
              180deg,
              rgb(102 102 102 / 15%) 0%,
              rgb(102 102 102 / 0%) 100%
            );
          }
        }
      }

      .global-Header_MainContentsWrap {
        @include mq(lg) {
          border-bottom: rem(1) solid $color-white !important;
        }
      }
    }
  }
}

.global-Header {
  position: relative;
  z-index: 120;

  &.-is-Fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;

    @include mq(lg) {
      position: static;
      top: auto;
      left: auto;
    }
  }

  &.-is-FollowUp {
    animation: animation-followuphm 500ms forwards;
  }

  &.-is-FollowDown {
    animation: animation-followdownhm 500ms forwards;
  }

  &:has(+ .local-PageTitle) {
    .global-Header_MainContentsWrap {
      @include mq(lg) {
        border-bottom: none;
      }
    }
  }
}

.global-Header_Shadow {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 131;
  display: none;
  width: 100%;
  height: 100dvh;
  background-color: rgb(0 0 0 / 40%);
}

.global-Header_MainContentsWrap {
  position: relative;
  z-index: 132;
  background-color: $color-white;
  border-bottom: rem(1) solid $color-border;
}

.global-Header_MainContents {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  justify-content: flex-start;
  max-width: calc($global-max-width + rem(56));
  padding: rem(13) rem(16);
  margin: 0 auto;

  @include mq(md) {
    padding: rem(13) rem(24);
  }

  @include mq(lg) {
    padding: rem(21) rem(28);
  }
}

.global-Header_LogoInner {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.global-Header_LogoMain {
  a {
    display: block;
    line-height: 1;
  }

  img {
    width: rem(98);
    max-width: rem(142);
    margin-right: rem(20);
    vertical-align: bottom;

    @include mq(md) {
      width: rem(117);
      margin-right: rem(26);
    }

    @include mq(lg) {
      width: rem(142);
      margin-right: rem(30);
    }
  }
}

.global-Header_LogoSub {
  font-family: $global-font-family-notosans;
  font-size: rem(10);
  font-weight: 400;
  line-height: rem(13.62);
  color: $color-gray1;

  @include mq(md) {
    font-size: rem(15);
    line-height: rem(20.43);
  }
}

.global-Header_BtnSearch {
  position: relative;
  width: rem(25);
  height: rem(25);
  margin-left: auto;
  cursor: pointer;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: rem(25);
    height: rem(25);
    content: "";
    background-image: $svg-search-black;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px 24px;
    opacity: 1;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: rem(25);
    height: rem(25);
    content: "";
    background-image: $svg-search-accent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px 24px;
    opacity: 0;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;
  }

  span {
    display: none;
  }

  @include mq(lg) {
    z-index: 134;
    margin-right: rem(48);
  }

  @include hover-without-touch {
    @include mq(lg) {
      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    }
  }
}

.global-Header_BtnMenu {
  margin-left: rem(14);
  cursor: pointer;

  @include mq(md) {
    margin-left: rem(24);
  }

  @include mq(lg) {
    display: none;
  }

  &::before {
    display: block;
    width: rem(25);
    height: rem(25);
    content: "";
    background-image: $svg-menu-black;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px 24px;
  }

  span {
    display: none;
  }
}

.global-Header_Search {
  position: absolute;
  top: rem(52);
  left: 0;
  z-index: 132;
  display: none;
  width: 100%;
  background-color: $color-white;

  @include mq(lg) {
    top: rem(73);
  }
}

.global-Header_SearchInner {
  padding: rem(22) rem(16);

  @include mq(lg) {
    position: relative;
    padding: rem(40) rem(0) rem(50);
  }
}

.global-Header_KeywordSearchInputInner {
  display: flex;
  flex-flow: nowrap;
  align-items: stretch;
  justify-content: space-between;

  @include mq(lg) {
    width: rem(800);
    margin: 0 auto;
  }
}

.global-Header_KeywordSearchInputInnerText {
  flex-grow: 1;

  & > input {
    width: 100%;
    height: 100%;
    padding: rem(11) rem(14);
    padding-left: rem(44);
    font-family: $global-font-family-sans-serif;
    font-size: 16px;
    line-height: rem(20.8);
    background-image: $svg-search-gray;
    background-repeat: no-repeat;
    background-position: rem(12) rem(12);
    background-size: rem(22) rem(22);
    border: rem(1) solid $color-border;
    border-radius: 0;
    appearance: none;

    @include mq(lg) {
      padding: rem(14);
      padding-left: rem(46);
      font-size: rem(18);
      line-height: rem(28.8);
      background-position: rem(12) rem(16);
      background-size: rem(24) rem(24);
    }

    &::placeholder {
      font-size: rem(13);
      font-weight: 400;
      line-height: rem(20.8);
      color: $color-gray2;
      text-align: left;
      opacity: 1;

      @include mq(lg) {
        font-size: rem(18);
        line-height: rem(28.8);
      }
    }
  }
}

.global-Header_KeywordSearchInputInnerBtn {
  display: none;

  & > button {
    position: relative;
    width: rem(46);
    height: 100%;
    font-family: $global-font-family-sans-serif;
    cursor: pointer;
    background: $color-gray1;
    border: 1px solid $color-gray1;

    @include mq(lg) {
      width: rem(160);
      overflow: hidden;
    }

    @include hover-without-touch {
      @include mq(lg) {
        border: 1px solid $color-accent;
      }
    }

    &.hvr-sweep-to-right::before {
      display: none;

      @include mq(lg) {
        display: block;
        background-color: $color-accent;
      }
    }

    & > span {
      display: block;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: rem(20);
        height: rem(20);
        content: "";
        background-image: $svg-search-l-white;
        background-size: 20px 20px;
        transform: translate(-50%, -50%);

        @include mq(lg) {
          transform: translate(-48px, -50%);
        }
      }

      & > span {
        display: none;

        @include mq(lg) {
          display: inherit;
          padding-left: 20px;
          font-size: rem(16);
          color: $color-white;
        }
      }
    }
  }
}

.global-Header_SearchCloseWrap {
  display: none;

  @include mq(lg) {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
  }
}

.global-Header_SearchCloseWrapInner {
  @include mq(lg) {
    position: relative;
    max-width: calc($global-max-width + 56px);
    margin: 0 auto;
    background-color: aqua;
  }
}

.global-Header_SearchClose {
  position: absolute;
  top: rem(35);
  right: rem(28);

  @include mq(global-l) {
    // right: calc((100vw - $global-max-width) / 2);
  }
}

.global-Header_SearchCloseBtn {
  position: relative;
  width: rem(25);
  height: rem(25);
  cursor: pointer;

  @include mq(lg) {
    right: rem(-7);
    width: rem(36);
    height: rem(36);
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: rem(25);
    height: rem(25);
    content: "";
    background-image: $svg-x-black;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px 24px;
    opacity: 1;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;

    @include mq(lg) {
      top: -1px;
      z-index: 121;
      width: rem(36);
      height: rem(36);
      background-size: 20px 20px;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: rem(25);
    height: rem(25);
    content: "";
    background-image: $svg-x-accent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px 24px;
    opacity: 0;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;

    @include mq(lg) {
      top: -1px;
      z-index: 121;
      width: rem(36);
      height: rem(36);
      background-image: $svg-x-white;
      background-size: 20px 20px;
    }
  }

  & > span {
    display: none;

    @include mq(lg) {
      display: block;

      &::before {
        position: absolute;
        top: -1px;
        right: 0;
        display: block;
        width: rem(36);
        height: rem(36);
        cursor: pointer;
        content: "";
        background-color: $color-gray8;
        border-radius: 50%;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
      }

      &::after {
        position: absolute;
        top: rem(16);
        right: rem(15);
        display: block;
        width: rem(0);
        height: rem(0);
        cursor: pointer;
        content: "";
        background-color: $color-gray1;
        border-radius: 50%;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
      }
    }

    & > span {
      @include mq(lg) {
        display: none;
      }
    }
  }

  @include hover-without-touch {
    @include mq(lg) {
      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }

      & > span {
        &::after {
          top: 0;
          right: 0;
          width: rem(36);
          height: rem(36);
        }
      }
    }
  }
}

.global-Header_MainMenu {
  position: absolute;
  top: rem(52);
  left: 0;
  z-index: 134;
  display: none;
  width: 100%;
  height: calc(100dvh - rem(52));
  overflow: auto;
  background-color: $color-white;

  @include mq(lg) {
    position: absolute;
    top: 0;
    display: block !important;
    height: auto;
    overflow: initial;
  }
}

.global-Header_MainMenuInner {
  padding: 0 0 rem(54);

  @include mq(lg) {
    position: relative;
    max-width: calc($global-max-width + rem(56));
    padding: 0;
    margin: 0 auto;
  }
}

.global-Header_MainMenuLink {
  @include mq(lg) {
    position: relative;
    position: absolute;
    top: rem(24);
    right: rem(124);
    z-index: 132;
    border-right: rem(1) solid $color-border;
  }

  ul {
    padding: 0;
    margin: 0;

    @include mq(lg) {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-end;
    }

    li {
      list-style: none;
      border-bottom: rem(1) solid $color-border;

      @include mq(lg) {
        margin-right: rem(24);
        border-bottom: none;
      }

      a {
        position: relative;
        display: block;
        padding: rem(14) rem(16);
        font-size: rem(16);
        font-weight: 400;
        line-height: rem(24);
        color: $color-black;
        text-decoration: none;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        @include mq(lg) {
          padding: 0;
          font-size: rem(13);
          line-height: rem(24);
        }

        @include hover-without-touch {
          @include mq(lg) {
            color: $color-accent;
          }
        }

        &[data-main-menu] {
          @include mq(lg) {
            padding: 0 rem(14) 0 0;
          }

          &::after {
            position: absolute;
            top: 50%;
            right: rem(12);
            display: block;
            width: rem(16);
            height: rem(16);
            content: "";
            background-image: $svg-arw-accent;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 16px 16px;
            transform: translate(0, -50%);

            @include mq(lg) {
              right: 0;
              width: rem(10);
              height: rem(10);
              background-size: 10px 10px;
              transform: translate(0, -50%) rotate(90deg);
            }
          }

          &.-is-active {
            @include mq(lg) {
              color: $color-accent;
            }

            &::before {
              @include mq(lg) {
                position: absolute;
                top: auto;
                bottom: rem(-25);
                left: 0;
                display: block;
                width: 100%;
                height: rem(2);
                content: "";
                background-color: $color-accent;
                animation: animation-borderwide 0.5s forwards;
              }
            }
          }
        }
      }
    }
  }
}

.global-Header_GlobalMenuTitle {
  position: relative;
  padding-left: rem(24);
  margin: rem(28) rem(15) 0;
  line-height: rem(24);
  cursor: pointer;

  @include mq(lg) {
    position: absolute;
    top: rem(24);
    right: rem(28);
    z-index: 132;
    width: rem(24);
    height: rem(24);
    margin: 0;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: rem(24);
    height: rem(24);
    content: "";
    background-image: $svg-global-black;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px 24px;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;
  }

  &::after {
    @include mq(lg) {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: rem(24);
      height: rem(24);
      content: "";
      background-image: $svg-global-accent;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 24px 24px;
      opacity: 0;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;
    }
  }

  @include hover-without-touch {
    @include mq(lg) {
      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  span {
    position: relative;
    display: inline-block;
    padding-right: rem(18);
    margin-left: rem(6);
    font-family: $global-font-family-notosans;
    font-size: rem(14);
    font-weight: 400;
    line-height: rem(24);

    @include mq(lg) {
      display: none;
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 0;
      height: 0;
      content: "";
      border-style: solid;
      border-top: 4.17px solid $color-gray1;
      border-right: 4px solid transparent;
      border-bottom: 0;
      border-left: 4px solid transparent;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;
      transform: translate(0, -50%);
    }
  }
}

.global-Header_GlobalMenuLink {
  display: none;
  margin: 0 rem(15);

  @include mq(lg) {
    position: absolute;
    top: rem(73);
    right: rem(14);
    z-index: 134;
    width: rem(223);
    margin: 0;
    background-color: $color-white;
    border-radius: rem(9);
    box-shadow: rem(2) rem(2) rem(20) rem(0) #0000001a;
  }

  ul {
    padding: rem(23) 0 0 rem(30);
    margin: 0;

    @include mq(lg) {
      padding: 0;
      padding: rem(28) rem(26);
    }

    li {
      margin-top: rem(20);
      line-height: rem(20.72);
      list-style: none;

      @include mq(lg) {
        line-height: rem(19.07);
      }

      &:first-child {
        margin-top: 0;
      }

      a {
        font-family: $global-font-family-notosans;
        font-size: rem(14);
        font-weight: 400;
        line-height: rem(20.72);
        color: $color-text;
        text-decoration: none;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        @include mq(lg) {
          line-height: rem(19.07);
          color: $color-black;
        }

        svg {
          width: 14px;
          height: 14px;
          margin-top: rem(2);
          vertical-align: top;
          fill: $color-gray2;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;

          @include mq(lg) {
            fill: $color-text;
          }
        }

        @include hover-without-touch {
          @include mq(lg) {
            color: $color-accent;

            svg {
              fill: $color-accent;
            }
          }
        }
      }
    }
  }
}

.global-Header_SubMenu {
  position: absolute;
  top: rem(52);
  left: 0;
  z-index: 134;
  display: none;
  width: 100%;
  height: calc(100dvh - rem(52));
  overflow: auto;
  overscroll-behavior: contain;
  background-color: $color-bg-area;
  transform: translateX(100%);

  @include mq(lg) {
    top: rem(73);
    z-index: 132;
    height: calc(100dvh - rem(73));
    background-color: initial;
    transform: translateX(0);
    animation: none;
  }

  &.-is-slidein {
    display: block !important;
    animation: animation-slidein 0.5s forwards;

    @include mq(lg) {
      animation: none;
    }
  }

  &.-is-slideout {
    display: block !important;
    animation: animation-slideout 0.5s forwards;

    @include mq(lg) {
      animation: none;
    }
  }

  &[data-main-menu="personal"] {
    padding-bottom: rem(42);

    @include mq(lg) {
      padding-bottom: rem(0);

      .global-Header_SubMenuInnerWrap {
        background: linear-gradient(
          90deg,
          $color-white 0%,
          $color-white 50%,
          $color-bg-area 50%,
          $color-bg-area 100%
        );
      }

      .global-Header_SubMenuMainAreaItem {
        &:nth-child(1) {
          flex-grow: 1;
          padding: rem(32) rem(64) rem(54) rem(0);
          background-color: $color-white;
        }

        &:nth-child(2) {
          flex-shrink: 0;
          width: rem(324);
          padding: 0 0 rem(54);
          background-color: $color-bg-area;
        }
      }

      .global-Header_SubMenuSubAreaItem {
        &:nth-child(1) {
          flex-shrink: 0;
          width: 100%;
        }
      }
    }
  }

  &[data-main-menu="business"] {
    padding-bottom: rem(42);

    .global-Header_SubMenuMainAreaItem {
      &:nth-child(2) {
        display: none;
      }
    }

    @include mq(lg) {
      padding-bottom: rem(0);

      .global-Header_SubMenuMainAreaItem {
        &:nth-child(1) {
          flex-shrink: 0;
          width: 23.25%;
          padding: rem(32) rem(0) rem(54) rem(0);
        }

        &:nth-child(2) {
          display: block;
          flex-grow: 1;
        }
      }

      .global-Header_SubMenuSubAreaItem {
        &:nth-child(1) {
          flex-shrink: 0;
          width: rem(383);
          border-left: rem(1) solid $color-border;
        }

        &:nth-child(2) {
          flex-grow: 1;
          border-left: rem(1) solid $color-border;
        }
      }
    }

    // 既存上書き
    @include mq(lg) {
      .global-Header_SubMenuFindMoreListTitle {
        position: relative;
        padding: 0;
        padding: rem(10) 0 rem(10) rem(20);
        padding-right: rem(28);
        margin-top: rem(48);
        margin-left: rem(-20);
        font-size: rem(15);
        font-weight: 400;
        line-height: rem(20);
        cursor: pointer;
        background-image: $svg-arw-gray2;
        background-repeat: no-repeat;
        background-position: right 16px center;
        background-size: 10px 10px;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        &::after {
          position: absolute;
          top: rem(-24);
          left: 50%;
          display: block;
          width: calc(100% - 40px);
          height: 1px;
          content: "";
          background-color: $color-border;
          transform: translate(-50%, 0);
        }

        @include hover-without-touch {
          color: $color-accent;
          background-color: $color-bg-area;
          background-image: $svg-arw-accent;
        }

        &.-is-active {
          color: $color-accent;
          background-color: $color-bg-area;
          background-image: $svg-arw-accent !important;
        }
      }

      .global-Header_SubMenuFindMoreListTitle::before {
        display: none;
      }

      .global-Header_SubMenuFindMore {
        padding: 0;
      }

      .global-Header_SubMenuFindMoreListInner {
        display: none;
      }

      .global-Header_SubMenuEvent {
        padding: 0;
        margin: rem(10) 0 0;
        border: none;
      }

      .global-Header_SubMenuEventLink a {
        padding: 0;
        font-size: rem(15);
        line-height: rem(20);
      }

      .global-Header_SubMenuEventLink a::before {
        display: none;
      }

      .global-Header_SubMenuFreeLink {
        margin: 0;
      }

      .global-Header_SubMenuFreeLink ul li {
        margin-top: rem(20);
        line-height: rem(20);
      }

      .global-Header_SubMenuFreeLink ul li a {
        padding: 0;
        font-size: rem(15);
        line-height: rem(20);
      }

      .global-Header_SubMenuFreeLink ul li a::before {
        display: none;
      }
    }

    .global-Header_SubMenuSubArea {
      @include mq(lg) {
        opacity: 0;
        animation: animation-fadein 0.5s forwards;

        // 製品・サービスから探す
        &[data-business-underlayer="business_index"] {
          .global-Header_SubMenuSubAreaItemInner {
            padding: rem(32) rem(0) rem(54) rem(20);
          }
        }

        // 事業分野から探す
        &[data-business-underlayer="business_field"] {
          .global-Header_SubMenuSubAreaItem:nth-child(1) {
            .global-Header_SubMenuSubAreaItemInner {
              padding: rem(32) rem(0) rem(54) rem(20);

              .global-Header_SubMenuUnderlayerSectionLink {
                margin-top: rem(16);
              }
            }
          }

          .global-Header_SubMenuSubAreaItem:nth-child(2) {
            .global-Header_SubMenuSubAreaItemInner {
              padding: rem(32) rem(12) rem(54) rem(40);
            }
          }
        }

        // キーワードから探す
        &[data-business-underlayer="business_keyword"] {
          .global-Header_SubMenuSubAreaItem {
            flex-grow: 1;

            .global-Header_SubMenuSubAreaItemInner {
              padding: rem(32) rem(0) rem(54) rem(40);
            }
          }
        }

        // 課題解決のヒント
        &[data-business-underlayer="business_assignment"] {
          .global-Header_SubMenuSubAreaItem {
            flex-grow: 1;

            .global-Header_SubMenuSubAreaItemInner {
              padding: rem(32) rem(0) rem(54) rem(40);
            }
          }
        }

        // 京セラをもっと知る
        &[data-business-underlayer="more"] {
          .global-Header_SubMenuSubAreaItem {
            flex-grow: 1;
          }

          .global-Header_SubMenuSubAreaItemInner {
            padding: rem(32) rem(0) rem(54) rem(38);
          }

          .global-Header_SubMenuFindMoreListInner {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            margin: 0;
            margin-left: rem(-28);
          }

          .global-Header_SubMenuFindMoreListItem:first-child {
            margin: rem(24) 0 0 rem(28);
          }

          .global-Header_SubMenuFindMoreListItem {
            flex-basis: calc(math.percentage(math.div(6, 12)) - rem(28));
            align-self: stretch;
            max-width: calc(math.percentage(math.div(6, 12)) - rem(28));
            margin: rem(24) 0 0 rem(28);
          }
        }
      }

      @include mq(global-l) {
        &[data-business-underlayer="more"] {
          .global-Header_SubMenuFindMoreListItem {
            flex-basis: calc(math.percentage(math.div(4, 12)) - rem(28));
            max-width: calc(math.percentage(math.div(4, 12)) - rem(28));
          }
        }
      }
    }
  }

  &[data-main-menu="company"] {
    padding-bottom: rem(42);

    .global-Header_SubMenuSubAreaItem {
      &:nth-child(1) {
        display: none;
      }
    }

    @include mq(lg) {
      padding-bottom: rem(0);

      .global-Header_SubMenuInnerWrap {
        background: linear-gradient(
          90deg,
          $color-white 0%,
          $color-white 50%,
          $color-bg-area 50%,
          $color-bg-area 100%
        );
      }

      .global-Header_SubMenuMainAreaItem {
        &:nth-child(1) {
          flex-shrink: 0;
          width: 32.01%;
          padding: rem(32) rem(0) rem(54) rem(0);
          background-color: $color-white;
        }

        &:nth-child(2) {
          flex-grow: 1;
        }
      }

      .global-Header_SubMenuSubAreaItem {
        &:nth-child(1) {
          display: block;
          flex-grow: 1;
          background-color: $color-white;
        }

        &:nth-child(2) {
          flex-shrink: 0;
          width: rem(324);
          padding: 0 0 rem(54);
          background-color: $color-bg-area;
        }
      }

      .global-Header_SubMenuSubAreaUnderlayer {
        height: 100%;
        padding: rem(32) rem(20) rem(54);
        border-left: rem(1) solid $color-border;
      }
    }

    // .global-Header_SubMenuImageLinkList関連の上書き
    @include mq(lg) {
      .global-Header_SubMenuImageLinkList {
        display: block;
      }

      .global-Header_SubMenuImageLinkList {
        margin: rem(24) 0 0;
      }

      .global-Header_SubMenuImageLinkListItem {
        max-width: none;
        margin: rem(16) 0 0 0;

        a {
          padding-right: rem(10);
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;

          @include hover-without-touch {
            background-color: $color-bg-area;
          }

          &.-is-underlayer {
            padding-right: rem(28);
            background-image: $svg-arw-gray2;
            background-repeat: no-repeat;
            background-position: right 16px center;
            background-size: 10px 10px;

            @include hover-without-touch {
              background-image: $svg-arw-accent;
            }
          }

          &.-is-active {
            background-color: $color-bg-area;
            background-image: $svg-arw-accent !important;

            .global-Header_SubMenuImageLinkListLabel {
              color: $color-accent;
            }
          }
        }
      }

      .global-Header_SubMenuImageLinkListItemInner {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
      }

      .global-Header_SubMenuImageLinkListLabel {
        margin: 0;
      }

      .global-Header_SubMenuImageLinkListImage {
        flex-shrink: 0;
        width: rem(120);
        margin-right: rem(16);
      }

      .global-Header_SubMenuImageLinkListLabel {
        line-height: rem(21.9);
      }
    }
  }

  &[data-main-menu="support"] {
    padding-bottom: rem(42);

    .global-Header_SubMenuSubAreaItem {
      &:nth-child(1) {
        display: none;
      }
    }

    @include mq(lg) {
      padding-bottom: rem(0);

      .global-Header_SubMenuInnerWrap {
        background: linear-gradient(90deg, $color-white 0%, $color-white 100%);
      }

      .global-Header_SubMenuMainAreaItem {
        &:nth-child(1) {
          flex-shrink: 0;
          width: 32.01%;
          padding: rem(32) rem(0) rem(54) rem(0);
          background-color: $color-white;
        }

        &:nth-child(2) {
          flex-grow: 1;
        }
      }
    }

    // .global-Header_SubMenuImageLinkList関連の上書き
    @include mq(lg) {
      .global-Header_SubMenuImageLinkList {
        display: block;
      }

      .global-Header_SubMenuImageLinkList {
        margin: rem(24) 0 0;
      }

      .global-Header_SubMenuImageLinkListItem {
        max-width: none;
        margin: 0 0 0 rem(-20);

        a {
          padding: rem(10) rem(20);
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;

          @include hover-without-touch {
            background-color: $color-bg-area;
          }

          &.-is-underlayer {
            padding-right: rem(28);
            background-image: $svg-arw-gray2;
            background-repeat: no-repeat;
            background-position: right 16px center;
            background-size: 10px 10px;

            @include hover-without-touch {
              background-image: $svg-arw-accent;
            }
          }

          &.-is-active {
            background-color: $color-bg-area;
            background-image: $svg-arw-accent !important;

            .global-Header_SubMenuImageLinkListLabel {
              color: $color-accent;
            }
          }
        }
      }

      .global-Header_SubMenuImageLinkListItemInner {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
      }

      .global-Header_SubMenuImageLinkListLabel {
        margin: 0;
      }

      .global-Header_SubMenuImageLinkListImage {
        flex-shrink: 0;
        width: rem(120);
        margin-right: rem(16);
      }

      .global-Header_SubMenuImageLinkListLabel {
        line-height: rem(21.9);
      }
    }
  }
}

.global-Header_SubMenuInnerWrap {
  @include mq(lg) {
    background-color: $color-white;
  }
}

.global-Header_SubMenuInner {
  @include mq(lg) {
    position: relative;
    max-width: calc($global-max-width + rem(56));
    padding: 0 rem(28);
    margin: 0 auto;
  }
}

.global-Header_SubMenuClose {
  padding: rem(18) rem(36);
  font-family: $global-font-family-notosans;
  font-size: rem(16);
  font-weight: 400;
  line-height: rem(16);
  background-color: $color-white;
  background-image: $svg-back-black;
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 20px 20px;
  border-bottom: rem(1) solid $color-border;

  @include mq(lg) {
    position: relative;
    right: rem(-7);
    z-index: 121;
    padding: 0;
    background-image: none;
    border-bottom: none;

    &::before {
      position: absolute;
      top: rem(34);
      right: 0;
      z-index: 121;
      display: block;
      width: rem(36);
      height: rem(36);
      cursor: pointer;
      content: "";
      background-image: $svg-x-black;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 20px 20px;
      opacity: 1;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;
    }

    &::after {
      position: absolute;
      top: rem(34);
      right: 0;
      z-index: 121;
      display: block;
      width: rem(36);
      height: rem(36);
      cursor: pointer;
      content: "";
      background-image: $svg-x-white;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 20px 20px;
      opacity: 0;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;
    }
  }

  @include hover-without-touch {
    @include mq(lg) {
      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }

      & > span {
        &::after {
          top: rem(34);
          right: 0;
          width: rem(36);
          height: rem(36);
        }
      }
    }
  }

  & > span {
    @include mq(lg) {
      &::before {
        position: absolute;
        top: rem(34);
        right: 0;
        display: block;
        width: rem(36);
        height: rem(36);
        cursor: pointer;
        content: "";
        background-color: $color-gray8;
        border-radius: 50%;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
      }

      &::after {
        position: absolute;
        top: rem(50);
        right: rem(15);
        display: block;
        width: rem(0);
        height: rem(0);
        cursor: pointer;
        content: "";
        background-color: $color-gray1;
        border-radius: 50%;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
      }
    }

    & > span {
      @include mq(lg) {
        display: none;
      }
    }
  }
}

.global-Header_SubMenuGoTop {
  border-bottom: rem(1) solid $color-border;

  @include mq(lg) {
    display: none;
  }

  a {
    display: block;
    padding: rem(15) rem(16);
    font-size: rem(15);
    font-weight: 400;
    line-height: rem(22.5);
    color: $color-black;
    text-decoration: none;
  }
}

.global-Header_SubMenuMainAreaInner {
  @include mq(lg) {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.global-Header_SubMenuMainAreaItem {
  align-self: stretch;
}

.global-Header_SubMenuSubArea {
  @include mq(lg) {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
  }
}

.global-Header_SubMenuSubAreaItem {
  align-self: stretch;
}

.global-Header_SubMenuSubAreaUnderlayerItem {
  display: none;
  opacity: 0;
  animation: animation-fadein 0.5s forwards;
}

.global-Header_SubMenuTitle {
  display: none;

  @include mq(lg) {
    display: block;
    padding-right: rem(46);
    font-size: rem(18);
    font-weight: 700;
    line-height: rem(27);
    color: $color-black;

    a {
      position: relative;
      display: inline-block;
      padding-right: rem(19);
      color: $color-black;
      text-decoration: none;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;

      &::before {
        position: absolute;
        top: 50%;
        right: rem(0);
        display: block;
        width: rem(14);
        height: rem(14);
        content: "";
        background-image: $svg-arw-gray2;
        background-size: 14px 14px;
        opacity: 1;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
        transform: translate(0, -50%);
      }

      &::after {
        position: absolute;
        top: 50%;
        right: rem(0);
        display: block;
        width: rem(14);
        height: rem(14);
        content: "";
        background-image: $svg-arw-accent;
        background-size: 14px 14px;
        opacity: 0;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
        transform: translate(0, -50%);
      }

      @include hover-without-touch {
        @include mq(lg) {
          color: $color-accent;

          &::before {
            opacity: 0;
          }

          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.global-Header_SubMenuImageLinkList {
  @include mq(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: rem(-24);
  }
}

.global-Header_SubMenuImageLinkListItem {
  border-bottom: rem(1) solid $color-border;

  @include mq(lg) {
    flex-basis: calc(math.percentage(math.div(4, 12)) - rem(24));
    align-self: stretch;
    max-width: calc(math.percentage(math.div(4, 12)) - rem(24));
    margin-top: rem(32);
    margin-left: rem(24);
    border-bottom: none;
  }

  @include mq(global-s) {
    flex-basis: calc(math.percentage(math.div(3, 12)) - rem(24));
    max-width: calc(math.percentage(math.div(3, 12)) - rem(24));
  }

  a {
    display: block;
    color: $color-black;
    text-decoration: none;

    @include hover-without-touch {
      @include mq(lg) {
        .global-Header_SubMenuImageLinkListLabel {
          color: $color-accent;

          svg {
            fill: $color-accent;
          }
        }

        .global-Header_SubMenuImageLinkListImage {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

.global-Header_SubMenuImageLinkListImage {
  display: none;

  @include mq(lg) {
    display: block;
    overflow: hidden;
    background: linear-gradient(180deg, #e3f6f9 0%, rgb(227 246 249 / 0%) 100%);
    border: rem(1) solid $color-border;
  }

  img {
    width: 100%;
    vertical-align: bottom;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;
  }
}

.global-Header_SubMenuImageLinkListLabel {
  padding: rem(15) rem(16);
  font-size: rem(15);
  font-weight: 400;
  line-height: rem(22.5);
  color: $color-black;

  @include mq(lg) {
    padding: 0;
    margin-top: rem(10);
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;
  }

  svg {
    width: rem(13);
    height: rem(13);
    fill: $color-gray1;
  }

  span {
    display: block;
    font-size: rem(12);
    font-weight: 400;
    line-height: rem(18);
  }
}

.global-Header_SubMenuFindMore {
  padding: rem(20) rem(16) 0;

  @include mq(lg) {
    padding: rem(40) rem(24) 0 rem(20);
  }
}

.global-Header_SubMenuFindMoreTitle {
  position: relative;
  padding: rem(16);
  font-size: rem(13);
  font-weight: 700;
  line-height: rem(13);
  color: $color-black;
  text-align: center;
  background-color: $color-white;
  border: rem(1) solid $color-border;

  @include mq(lg) {
    display: none;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: rem(16);
    display: block;
    width: rem(12);
    height: rem(12);
    content: "";
    background-image: $svg-arw-accent;
    background-size: 12px 12px;
    transform: translate(0, -50%) rotate(90deg);
  }
}

.global-Header_SubMenuFindMoreList {
  display: none;

  @include mq(lg) {
    display: block;
  }
}

.global-Header_SubMenuFindMoreListTitle {
  position: relative;
  padding-left: rem(20);
  font-size: rem(12);
  font-weight: 700;
  line-height: rem(12);

  @include mq(lg) {
    font-size: rem(13);
    line-height: rem(19.5);
  }

  &::before {
    position: absolute;
    top: 50%;
    left: rem(2);
    display: block;
    width: rem(12);
    height: rem(3);
    content: "";
    background-color: $color-black;
    transform: translate(0, -50%);
  }
}

.global-Header_SubMenuFindMoreListInner {
  margin-top: rem(15);

  @include mq(lg) {
    margin-top: rem(20);
  }
}

.global-Header_SubMenuFindMoreListItem {
  margin-top: rem(20);

  &:first-child {
    margin-top: 0;
  }

  a {
    display: block;
    color: $color-black;
    text-decoration: none;

    @include hover-without-touch {
      @include mq(lg) {
        .global-Header_SubMenuFindMoreListItemLabel {
          color: $color-accent;

          svg {
            fill: $color-accent;
          }
        }

        .global-Header_SubMenuFindMoreListItemImage {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

.global-Header_SubMenuFindMoreListItemInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.global-Header_SubMenuFindMoreListItemImage {
  flex-shrink: 0;
  width: rem(140);
  margin-right: rem(12);
  border: rem(1) solid $color-border;

  @include mq(lg) {
    margin-right: rem(16);
    overflow: hidden;
  }

  img {
    width: 100%;
    vertical-align: bottom;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;
  }
}

.global-Header_SubMenuFindMoreListItemLabel {
  font-size: rem(13);
  font-weight: 400;
  line-height: rem(19.5);
  transition-timing-function: $transition-timing-function;
  transition-duration: $transition-duration;

  svg {
    width: rem(13);
    height: rem(13);
    fill: $color-gray1;
  }
}

.global-Header_SubMenuFindMoreListLinkSp {
  margin-top: rem(16);

  @include mq(lg) {
    display: none;
  }

  a {
    position: relative;
    display: block;
    padding: rem(16);
    font-size: rem(13);
    font-weight: 700;
    line-height: rem(13);
    color: $color-black;
    text-align: center;
    text-decoration: none;
    background-color: $color-white;
    border: rem(1) solid $color-border;
  }
}

.global-Header_SubMenuFindMoreListLinkPc {
  display: none;

  @include mq(lg) {
    display: block;
    margin-top: rem(20);
    font-size: rem(13);
    font-weight: 400;
    line-height: rem(19.5);

    a {
      position: relative;
      display: inline-block;
      padding-right: rem(16);
      color: $color-black;
      text-decoration: none;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;

      &::before {
        position: absolute;
        top: 50%;
        right: rem(0);
        display: block;
        width: rem(12);
        height: rem(12);
        content: "";
        background-image: $svg-arw-gray1;
        background-size: 12px 12px;
        opacity: 1;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
        transform: translate(0, -50%);
      }

      &::after {
        position: absolute;
        top: 50%;
        right: rem(0);
        display: block;
        width: rem(12);
        height: rem(12);
        content: "";
        background-image: $svg-arw-accent;
        background-size: 12px 12px;
        opacity: 0;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
        transform: translate(0, -50%);
      }

      @include hover-without-touch {
        @include mq(lg) {
          color: $color-accent;

          &::before {
            opacity: 0;
          }

          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.global-Header_SubMenuEvent {
  margin: rem(16) rem(16) 0;

  @include mq(lg) {
    padding: rem(24) 0 0;
    margin: rem(28) rem(24) 0 rem(20);
    border-top: rem(1) solid $color-border;
  }
}

.global-Header_SubMenuEventLink {
  @include mq(lg) {
    line-height: rem(19.5);
  }

  a {
    position: relative;
    display: block;
    padding: rem(16);
    font-size: rem(13);
    font-weight: 700;
    line-height: rem(13);
    color: $color-black;
    text-align: center;
    text-decoration: none;
    background-color: $color-white;
    border: rem(1) solid $color-border;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;

    @include mq(lg) {
      position: relative;
      display: inline-block;
      padding: 0;
      padding-left: rem(16);
      font-size: rem(13);
      font-weight: 400;
      line-height: rem(19.5);
      color: $color-black;
      text-align: left;
      text-decoration: none;
      background-color: initial;
      border: none;

      &::before {
        position: absolute;
        top: 50%;
        left: rem(0);
        display: block;
        width: 0;
        height: 0;
        content: "";
        border-style: solid;
        border-top: 4.5px solid transparent;
        border-right: 0;
        border-bottom: 4.5px solid transparent;
        border-left: 8px solid $color-gray1;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
        transform: translate(0, -50%);
      }

      @include hover-without-touch {
        color: $color-accent;

        &::before {
          border-left: 8px solid $color-accent;
        }
      }
    }
  }
}

.global-Header_SubMenuFreeLink {
  margin: rem(24) rem(16) 0;

  @include mq(lg) {
    margin: rem(0) rem(24) 0 rem(20);
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      margin-top: rem(7);
      line-height: rem(19.5);
      list-style: none;

      a {
        position: relative;
        display: inline-block;
        padding-left: rem(16);
        font-size: rem(13);
        font-weight: 400;
        line-height: rem(19.5);
        color: $color-black;
        text-decoration: none;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        &::before {
          position: absolute;
          top: 50%;
          left: rem(0);
          display: block;
          width: 0;
          height: 0;
          content: "";
          border-style: solid;
          border-top: 4.5px solid transparent;
          border-right: 0;
          border-bottom: 4.5px solid transparent;
          border-left: 8px solid $color-gray1;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;
          transform: translate(0, -50%);
        }

        svg {
          width: rem(13);
          height: rem(13);
          margin-left: rem(2);
          fill: $color-gray1;
        }

        @include hover-without-touch {
          color: $color-accent;

          &::before {
            border-left: 8px solid $color-accent;
          }

          svg {
            fill: $color-accent;
          }
        }
      }
    }
  }
}

.global-Header_SubMenuUnderlayerLink {
  @include mq(lg) {
    margin-top: rem(34);

    ul {
      padding: 0;
      margin: 0;

      li {
        margin-top: rem(20);
        font-size: rem(15);
        font-weight: 400;
        line-height: rem(20);
        color: $color-black;
        list-style: none;

        a {
          color: $color-black;
          text-decoration: none;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;

          svg {
            width: rem(13);
            height: rem(13);
            fill: $color-gray1;
          }

          @include hover-without-touch {
            color: $color-accent;

            svg {
              fill: $color-accent;
            }
          }
        }
      }
    }
  }
}

.global-Header_SubMenuExpand {
  @include mq(lg) {
    margin-top: rem(16);
  }
}

.global-Header_SubMenuExpandItem {
  border-bottom: rem(1) solid $color-border;

  @include mq(lg) {
    &.-is-underlayer {
      border-bottom: none;

      .global-Header_SubMenuExpandItemTitle {
        margin-left: rem(-20);
        line-height: rem(20);
      }

      .global-Header_SubMenuExpandItemTitle a {
        padding: rem(10) rem(20);
        padding-right: rem(28);
        font-weight: 400;
        background-image: $svg-arw-gray2;
        background-repeat: no-repeat;
        background-position: right 16px center;
        background-size: 10px 10px;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        &::after {
          display: none;
        }

        @include hover-without-touch {
          color: $color-accent;
          background-color: $color-bg-area;
          background-image: $svg-arw-accent;
        }
      }
    }

    &.-is-active {
      .global-Header_SubMenuExpandItemTitle a {
        color: $color-accent;
        background-color: $color-bg-area;
        background-image: $svg-arw-accent;
      }
    }
  }
}

.global-Header_SubMenuExpandItemTitle {
  font-size: rem(15);
  font-weight: 400;
  line-height: rem(22.5);

  a {
    display: block;
    padding: rem(14) rem(16);
    color: $color-black;
    text-decoration: none;
  }

  &.-is-expand {
    position: relative;

    a {
      &::after {
        position: absolute;
        top: 50%;
        right: rem(14);
        display: block;
        width: 16px;
        height: 16px;
        font-size: 0;
        line-height: 0;
        content: "";
        background-image: $svg-arw-accent;
        background-repeat: no-repeat;
        background-size: 16px 16px;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
        transform: translate(0, -50%) rotate(90deg);
      }
    }

    &.-is-open {
      font-weight: 700;

      a {
        &::after {
          transform: translate(0, -50%) rotate(-90deg);
        }
      }
    }
  }
}

.global-Header_SubMenuExpandItemMain {
  display: none;

  @include mq(lg) {
    display: none !important;
  }
}

.global-Header_SubMenuExpandItemMainLink {
  padding: 0 0 rem(18);
  margin: rem(8) rem(16) 0;

  @include mq(lg) {
    display: none;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      margin-top: rem(16);
      line-height: rem(22.5);
      list-style: none;

      &:first-child {
        margin-top: rem(0);
      }

      a {
        position: relative;
        display: block;
        padding-left: rem(16);
        font-size: rem(15);
        font-weight: 400;
        line-height: rem(22.5);
        color: $color-black;
        text-decoration: none;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        &::before {
          position: absolute;
          top: 50%;
          left: rem(0);
          display: block;
          width: 0;
          height: 0;
          content: "";
          border-style: solid;
          border-top: 4.5px solid transparent;
          border-right: 0;
          border-bottom: 4.5px solid transparent;
          border-left: 8px solid $color-gray1;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;
          transform: translate(0, -50%);
        }

        svg {
          width: rem(13);
          height: rem(13);
          fill: $color-gray1;
        }
      }
    }
  }
}

.global-Header_SubMenuExpandItemMainSearch {
  padding: 0 0 rem(23);
  margin: 0 rem(16);

  @include mq(lg) {
    padding: 0;
    margin: rem(24) 0 0;
  }
}

.global-Header_BusinessSearch {
  @include mq(lg) {
    max-width: rem(740);
  }
}

.global-Header_BusinessSearchInner {
  width: 100%;
}

.global-Header_BusinessSearchInput {
}

.global-Header_BusinessSearchInputInner {
  display: flex;
  flex-flow: nowrap;
  align-items: stretch;
  justify-content: space-between;
}

.global-Header_BusinessSearchInputInnerText {
  flex-grow: 1;

  & > input {
    width: 100%;
    height: 100%;
    padding: rem(12) rem(14);
    padding-left: rem(44);
    font-family: $global-font-family-sans-serif;
    font-size: 16px;
    line-height: rem(1.1);
    background-image: $svg-search-gray;
    background-repeat: no-repeat;
    background-position: rem(12) rem(12);
    background-size: rem(22) rem(22);
    border: 1px solid $color-border;
    border-radius: 0;
    appearance: none;

    @include mq(lg) {
      padding: rem(12) rem(14);
      padding-left: rem(44);
      font-size: rem(18);
      background-size: rem(24) rem(24);
    }

    &::placeholder {
      font-size: rem(13);
      font-weight: 400;
      line-height: rem(1.1);
      color: $color-gray2;
      text-align: left;

      @include mq(lg) {
        font-size: rem(18);
      }
    }
  }
}

.global-Header_BusinessSearchInputInnerBtn {
  display: none;

  & > button {
    position: relative;
    width: rem(46);
    height: 100%;
    font-family: $global-font-family-sans-serif;
    background: $color-gray1;
    border: 1px solid $color-gray1;

    @include mq(lg) {
      width: rem(148);
      overflow: hidden;
      cursor: pointer;
    }

    @include hover-without-touch {
      @include mq(lg) {
        border: 1px solid $color-accent;
      }
    }

    &.hvr-sweep-to-right::before {
      @include mq(lg) {
        display: block;
        background-color: $color-accent;
      }
    }

    & > span {
      display: block;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: rem(20);
        height: rem(20);
        content: "";
        background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.2059 8.43653C15.2059 9.93045 14.7209 11.3105 13.9038 12.4301L18.025 16.5538C18.4319 16.9607 18.4319 17.6214 18.025 18.0282C17.6181 18.4351 16.9573 18.4351 16.5504 18.0282L12.4292 13.9045C11.3094 14.7247 9.92916 15.2064 8.435 15.2064C4.69471 15.2064 1.66406 12.1762 1.66406 8.43653C1.66406 4.69684 4.69471 1.66669 8.435 1.66669C12.1753 1.66669 15.2059 4.69684 15.2059 8.43653ZM8.435 13.1233C9.05058 13.1233 9.66013 13.0021 10.2289 12.7666C10.7976 12.531 11.3143 12.1858 11.7496 11.7506C12.1849 11.3154 12.5302 10.7987 12.7657 10.2301C13.0013 9.66146 13.1226 9.05201 13.1226 8.43653C13.1226 7.82105 13.0013 7.21159 12.7657 6.64296C12.5302 6.07433 12.1849 5.55766 11.7496 5.12245C11.3143 4.68724 10.7976 4.34201 10.2289 4.10648C9.66013 3.87094 9.05058 3.74972 8.435 3.74972C7.81942 3.74972 7.20986 3.87094 6.64114 4.10648C6.07242 4.34201 5.55567 4.68724 5.12038 5.12245C4.6851 5.55766 4.33982 6.07433 4.10425 6.64296C3.86867 7.21159 3.74743 7.82105 3.74743 8.43653C3.74743 9.05201 3.86867 9.66146 4.10425 10.2301C4.33982 10.7987 4.6851 11.3154 5.12038 11.7506C5.55567 12.1858 6.07242 12.531 6.64114 12.7666C7.20986 13.0021 7.81942 13.1233 8.435 13.1233Z" fill="%23ffffff"/></svg>');
        background-size: 20px 20px;
        transform: translate(-50%, -50%);

        @include mq(lg) {
          transform: translate(-48px, -50%);
        }
      }

      & > span {
        display: none;

        @include mq(lg) {
          display: inherit;
          padding-left: 20px;
          font-size: rem(16);
          color: $color-white;
        }
      }
    }
  }
}

.global-Header_BusinessSearchSelect {
  margin-top: rem(12);

  @include mq(lg) {
    margin-top: rem(20);

    fieldset {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}

.global-Header_BusinessSearchSelectRadio {
  margin-top: rem(12);

  @include mq(lg) {
    margin-top: rem(0);
  }

  &:first-child {
    @include mq(lg) {
      margin-right: rem(16);
    }
  }

  label {
    position: relative;
    padding-left: rem(30);
    font-size: rem(14);
    font-weight: 400;
    line-height: rem(22.4);
    cursor: pointer;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: rem(22);
      height: rem(22);
      content: "";
      background-color: $color-white;
      border: rem(1) solid $color-border;
      border-radius: 100px;
      transform: translate(0, -50%);
    }

    &::after {
      position: absolute;
      top: 50%;
      left: rem(7);
      display: block;
      width: rem(8);
      height: rem(8);
      content: "";
      background-color: $color-white;
      border-radius: 100px;
      transform: translate(0, -50%);
    }
  }

  input {
    display: none;

    &:checked + label {
      &::before {
        background-color: $color-accent;
        border: rem(1) solid $color-accent;
      }
    }
  }
}

.global-Header_BusinessSearchLink {
  margin-top: rem(32);

  @include mq(lg) {
    margin-top: rem(48);
  }
}

.global-Header_BusinessSearchTitle {
  position: relative;
  font-size: rem(12);
  font-weight: 700;
  line-height: rem(19.2);

  @include mq(lg) {
    font-size: rem(14);
    line-height: rem(22.4);
  }
}

.global-Header_BusinessSearchList {
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: rem(-8);

  @include mq(lg) {
    margin-top: rem(2);
    margin-left: rem(-10);
  }
}

.global-Header_BusinessSearchListItem {
  margin-top: rem(8);
  margin-left: rem(8);

  @include mq(lg) {
    margin-top: rem(10);
    margin-left: rem(10);
  }

  a {
    display: block;
    padding: rem(9) rem(10);
    font-size: rem(12);
    font-weight: 400;
    line-height: rem(12);
    color: $color-black;
    text-decoration: none;
    background-color: $color-white;
    border: rem(1) solid $color-border;
    border-radius: rem(6);
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;

    @include mq(lg) {
      padding: rem(9) rem(10);
      font-size: rem(13);
      line-height: rem(13);
      background-color: $color-bg-ui;
    }

    @include hover-without-touch {
      color: $color-accent;
    }
  }
}

.global-Header_SubMenuUnderlayerSection {
  @include mq(lg) {
    margin-top: rem(26);
  }
}

.global-Header_SubMenuUnderlayerSectionTitle {
  @include mq(lg) {
    position: relative;
    padding-right: rem(10);
    padding-left: rem(18);
    font-size: rem(13);
    font-weight: 700;
    line-height: rem(19.5);

    &::before {
      position: absolute;
      top: rem(10);
      left: 0;
      display: block;
      width: rem(12);
      height: rem(3);
      content: "";
      background-color: $color-black;
      transform: translate(0, -50%);
    }
  }
}

.global-Header_SubMenuUnderlayerSectionLink {
  @include mq(lg) {
    margin-top: rem(10);

    ul {
      padding: 0;
      margin: 0;

      li {
        font-size: rem(15);
        font-weight: 400;
        line-height: rem(20);
        list-style: none;

        a {
          display: block;
          padding: rem(10) rem(32) rem(10) rem(20);
          margin-left: rem(-20);
          color: $color-black;
          text-decoration: none;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;

          &.-is-underlayer {
            padding-right: rem(32);
            background-image: $svg-arw-gray2;
            background-repeat: no-repeat;
            background-position: right 16px center;
            background-size: 10px 10px;

            @include hover-without-touch {
              background-image: $svg-arw-accent;
            }
          }

          &.-is-active {
            color: $color-accent;
            background-color: $color-bg-area;
            background-image: $svg-arw-accent;
          }

          @include hover-without-touch {
            color: $color-accent;
            background-color: $color-bg-area;
          }
        }
      }
    }
  }
}

.global-Header_SubMenuUnderlayerExpand {
  @include mq(lg) {
    opacity: 0;
    animation: animation-fadein 0.5s forwards;
  }
}

.global-Header_SubMenuUnderlayerExpandItemWrap {
  @include mq(lg) {
    margin-top: rem(16);
  }
}

.global-Header_SubMenuUnderlayerExpandItemTitle {
  font-size: rem(15);
  font-weight: 400;
  line-height: rem(20);
  color: $color-black;

  &.-is-expand {
    position: relative;

    a {
      padding-right: rem(28);

      @include mq(lg) {
        padding-right: rem(34);
      }

      &::after {
        position: absolute;
        top: 50%;
        right: rem(16);
        display: block;
        width: 10px;
        height: 10px;
        font-size: 0;
        line-height: 0;
        content: "";
        background-image: $svg-arw-accent;
        background-repeat: no-repeat;
        background-size: 10px 10px;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
        transform: translate(0, -50%) rotate(90deg);
      }
    }

    &.-is-open {
      a {
        color: $color-accent;
        background-color: $color-bg-area;

        &::after {
          transform: translate(0, -50%) rotate(-90deg);
        }
      }
    }
  }

  a {
    display: block;
    padding: rem(10) rem(0) rem(10) rem(20);
    margin-left: rem(-20);
    color: $color-black;
    text-decoration: none;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;

    @include mq(lg) {
      padding: rem(10) rem(34) rem(10) rem(20);

      span {
        display: block;
        font-size: rem(11);
        font-weight: 400;
        line-height: rem(18);
      }
    }

    @include hover-without-touch {
      color: $color-accent;
      background-color: $color-bg-area;
    }
  }
}

.global-Header_SubMenuUnderlayerExpandItemList {
  @include mq(lg) {
    display: none;
    margin-left: rem(-20);

    ul {
      padding: 0;
      margin: 0;

      li {
        font-size: rem(15);
        font-weight: 400;
        line-height: rem(20);
        list-style: none;

        a {
          display: block;
          padding: rem(10) rem(34) rem(10) rem(50);
          color: $color-black;
          text-decoration: none;
          background-color: $color-bg-area;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;

          &.-is-underlayer {
            padding-right: rem(28);
            background-image: $svg-arw-gray2;
            background-repeat: no-repeat;
            background-position: right 16px center;
            background-size: 10px 10px;

            @include hover-without-touch {
              background-image: $svg-arw-accent;
            }
          }

          &.-is-active {
            color: $color-accent;
            background-color: $color-bg-area;
            background-image: $svg-arw-accent;
          }

          @include hover-without-touch {
            color: $color-accent;

            svg {
              fill: $color-accent;
            }
          }

          svg {
            width: rem(13);
            height: rem(13);
            fill: $color-gray2;
            transition-timing-function: $transition-timing-function;
            transition-duration: $transition-duration;
          }
        }
      }
    }
  }
}

.global-Header_SubMenuUnderlayerField {
  @include mq(lg) {
    opacity: 0;
    animation: animation-fadein 0.5s forwards;
  }
}

.global-Header_SubMenuUnderlayerFieldItem {
  @include mq(lg) {
    margin-top: rem(24);

    a {
      display: inline-block;
      text-decoration: none;

      @include hover-without-touch {
        .global-Header_SubMenuUnderlayerFieldLink {
          & > span {
            &::after {
              background-image: $svg-arw-white;
              background-size: 12px 12px;
            }

            & > span {
              color: $color-accent;

              &::after {
                top: 0;
                right: 0;
                width: rem(36);
                height: rem(36);
              }
            }
          }
        }
      }
    }
  }
}

.global-Header_SubMenuUnderlayerFieldImage {
  @include mq(lg) {
    img {
      width: 100%;
      vertical-align: bottom;
    }
  }
}

.global-Header_SubMenuUnderlayerFieldText {
  @include mq(lg) {
    margin-top: rem(16);
    font-size: rem(15);
    font-weight: 400;
    line-height: rem(24);
  }
}

.global-Header_SubMenuUnderlayerFieldLink {
  @include mq(lg) {
    margin-top: rem(16);

    & > span {
      position: relative;
      display: table;
      width: auto;
      height: 100%;
      color: $color-black;

      &::after {
        position: absolute;
        top: 50%;
        right: rem(11);
        z-index: 122;
        display: block;
        width: rem(12);
        height: rem(12);
        content: "";
        background-image: $svg-arw-accent;
        background-size: 12px 12px;
        transform: translate(0, -50%) rotate(0deg);
      }

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: rem(36);
        height: rem(36);
        content: "";
        background-color: $color-white;
        border: rem(1) solid $color-border;
        border-radius: 50%;
      }

      & > span {
        display: table-cell;
        height: rem(36);
        padding: 0 rem(44) 0 0;
        font-size: rem(15);
        font-weight: 700;
        line-height: 100%;
        vertical-align: middle;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        &::after {
          position: absolute;
          top: 50%;
          right: rem(21);
          z-index: 121;
          display: block;
          width: rem(0);
          height: rem(0);
          content: "";
          background-color: $color-accent;
          border-radius: 50%;
          transition: all 0.22s linear;
        }
      }
    }
  }
}

.global-Header_SubMenuUnderlayerAssignment {
  @include mq(lg) {
    margin-top: rem(24);

    a {
      display: inline-block;
      text-decoration: none;

      @include hover-without-touch {
        .global-Header_SubMenuUnderlayerAssignmentLink {
          & > span {
            &::after {
              background-image: $svg-arw-white;
              background-size: 12px 12px;
            }

            & > span {
              color: $color-accent;

              &::after {
                top: 0;
                right: 0;
                width: rem(36);
                height: rem(36);
              }
            }
          }
        }
      }
    }
  }
}

.global-Header_SubMenuUnderlayerAssignmentText {
  @include mq(lg) {
    font-size: rem(15);
    font-weight: 400;
    line-height: rem(24);
  }
}

.global-Header_SubMenuUnderlayerAssignmentLink {
  @include mq(lg) {
    margin-top: rem(30);

    & > span {
      position: relative;
      display: table;
      width: auto;
      height: 100%;
      color: $color-black;

      &::after {
        position: absolute;
        top: 50%;
        right: rem(11);
        z-index: 122;
        display: block;
        width: rem(12);
        height: rem(12);
        content: "";
        background-image: $svg-arw-accent;
        background-size: 12px 12px;
        transform: translate(0, -50%) rotate(0deg);
      }

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: rem(36);
        height: rem(36);
        content: "";
        background-color: $color-white;
        border: rem(1) solid $color-border;
        border-radius: 50%;
      }

      & > span {
        display: table-cell;
        height: rem(36);
        padding: 0 rem(44) 0 0;
        font-size: rem(15);
        font-weight: 700;
        line-height: 100%;
        vertical-align: middle;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        &::after {
          position: absolute;
          top: 50%;
          right: rem(21);
          z-index: 121;
          display: block;
          width: rem(0);
          height: rem(0);
          content: "";
          background-color: $color-accent;
          border-radius: 50%;
          transition: all 0.22s linear;
        }
      }
    }
  }
}

.global-Header_FeaturedSolutions {
  padding: rem(8) rem(10);
  background-color: $color-gray9;

  @include mq(lg) {
    padding: rem(27) rem(0) rem(20) rem(0);
    margin-right: rem(20);
    background-color: $color-white;
    border-bottom: rem(1) solid $color-border-ui;
  }

  & + .global-Header_SubMenuUnderlayerSection {
    margin-top: rem(30);
  }
}

.global-Header_FeaturedSolutionsTitle {
  padding: 0 rem(8);
  font-size: rem(13);
  font-weight: 700;
  line-height: rem(20);
  color: $color-gray1;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @include mq(lg) {
    padding: 0 rem(0);
  }
}

.global-Header_FeaturedSolutionsList {
  padding: 0 0 rem(10);
  margin: rem(14) rem(6) 0;

  @include mq(lg) {
    padding: 0 0 rem(0);
    margin: rem(6) rem(0) 0;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      margin-top: rem(16);
      line-height: rem(22.5);
      list-style: none;

      @include mq(lg) {
        margin-top: rem(0);
      }

      &:first-child {
        margin-top: rem(0);
      }

      a {
        position: relative;
        display: block;
        padding-left: rem(16);
        font-size: rem(15);
        font-weight: 400;
        line-height: rem(22.5);
        color: $color-black;
        text-decoration: none;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        @include mq(lg) {
          padding: rem(10) rem(20);
          margin-right: rem(-20);
          margin-left: rem(-20);
        }

        &.-is-active {
          color: $color-accent;
          background-color: $color-bg-area;
          background-image: $svg-arw-accent;
        }

        @include hover-without-touch {
          color: $color-accent;
          background-color: $color-bg-area;
        }

        &::before {
          position: absolute;
          top: 50%;
          left: rem(0);
          display: block;
          width: 0;
          height: 0;
          content: "";
          border-style: solid;
          border-top: 4.5px solid transparent;
          border-right: 0;
          border-bottom: 4.5px solid transparent;
          border-left: 8px solid $color-gray1;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;
          transform: translate(0, -50%);

          @include mq(lg) {
            display: none;
          }
        }

        svg {
          width: rem(13);
          height: rem(13);
          fill: $color-gray1;
        }
      }
    }
  }
}

@use "sass:math";

.local-Search_Sp {
  padding: rem(12) rem(20);
  background-color: $color-gray5;

  @include mq(lg) {
    display: none;
  }
}

.local-Search_SpInner {
  form {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.local-Search_SpInput {
  flex-grow: 1;
  height: rem(46);
  padding: 0 rem(14);
  font-size: 16px;
  border: rem(1) solid $color-border;
  border-right: none;
  border-radius: 0;
  appearance: none;

  &::placeholder {
    font-size: rem(13);
    font-weight: 400;
    line-height: rem(1.1);
    color: $color-gray2;
  }
}

.local-Search_SpSubmit {
  width: rem(45);
  height: rem(46);
  padding: 0;
  cursor: pointer;
  background-color: $color-gray1;
  background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.2059 8.43653C15.2059 9.93045 14.7209 11.3105 13.9038 12.4301L18.025 16.5538C18.4319 16.9607 18.4319 17.6214 18.025 18.0282C17.6181 18.4351 16.9573 18.4351 16.5504 18.0282L12.4292 13.9045C11.3094 14.7247 9.92916 15.2064 8.435 15.2064C4.69471 15.2064 1.66406 12.1762 1.66406 8.43653C1.66406 4.69684 4.69471 1.66669 8.435 1.66669C12.1753 1.66669 15.2059 4.69684 15.2059 8.43653ZM8.435 13.1233C9.05058 13.1233 9.66013 13.0021 10.2289 12.7666C10.7976 12.531 11.3143 12.1858 11.7496 11.7506C12.1849 11.3154 12.5302 10.7987 12.7657 10.2301C13.0013 9.66146 13.1226 9.05201 13.1226 8.43653C13.1226 7.82105 13.0013 7.21159 12.7657 6.64296C12.5302 6.07433 12.1849 5.55766 11.7496 5.12245C11.3143 4.68724 10.7976 4.34201 10.2289 4.10648C9.66013 3.87094 9.05058 3.74972 8.435 3.74972C7.81942 3.74972 7.20986 3.87094 6.64114 4.10648C6.07242 4.34201 5.55567 4.68724 5.12038 5.12245C4.6851 5.55766 4.33982 6.07433 4.10425 6.64296C3.86867 7.21159 3.74743 7.82105 3.74743 8.43653C3.74743 9.05201 3.86867 9.66146 4.10425 10.2301C4.33982 10.7987 4.6851 11.3154 5.12038 11.7506C5.55567 12.1858 6.07242 12.531 6.64114 12.7666C7.20986 13.0021 7.81942 13.1233 8.435 13.1233Z" fill="%23ffffff"/></svg>');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px 20px;
  border: rem(1) solid $color-border;
  outline: none;
  appearance: none;
}

.local-Search_Pc {
  display: none;

  @include mq(lg) {
    display: block;
    padding: rem(6) 0 0;
    margin-left: rem(24);
  }
}

.local-Search_PcInput {
  width: rem(273);
  height: rem(28);
  padding: 0 rem(10) 0 rem(28);
  font-size: 13px;
  vertical-align: top;
  background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.2059 8.43653C15.2059 9.93045 14.7209 11.3105 13.9038 12.4301L18.025 16.5538C18.4319 16.9607 18.4319 17.6214 18.025 18.0282C17.6181 18.4351 16.9573 18.4351 16.5504 18.0282L12.4292 13.9045C11.3094 14.7247 9.92916 15.2064 8.435 15.2064C4.69471 15.2064 1.66406 12.1762 1.66406 8.43653C1.66406 4.69684 4.69471 1.66669 8.435 1.66669C12.1753 1.66669 15.2059 4.69684 15.2059 8.43653ZM8.435 13.1233C9.05058 13.1233 9.66013 13.0021 10.2289 12.7666C10.7976 12.531 11.3143 12.1858 11.7496 11.7506C12.1849 11.3154 12.5302 10.7987 12.7657 10.2301C13.0013 9.66146 13.1226 9.05201 13.1226 8.43653C13.1226 7.82105 13.0013 7.21159 12.7657 6.64296C12.5302 6.07433 12.1849 5.55766 11.7496 5.12245C11.3143 4.68724 10.7976 4.34201 10.2289 4.10648C9.66013 3.87094 9.05058 3.74972 8.435 3.74972C7.81942 3.74972 7.20986 3.87094 6.64114 4.10648C6.07242 4.34201 5.55567 4.68724 5.12038 5.12245C4.6851 5.55766 4.33982 6.07433 4.10425 6.64296C3.86867 7.21159 3.74743 7.82105 3.74743 8.43653C3.74743 9.05201 3.86867 9.66146 4.10425 10.2301C4.33982 10.7987 4.6851 11.3154 5.12038 11.7506C5.55567 12.1858 6.07242 12.531 6.64114 12.7666C7.20986 13.0021 7.81942 13.1233 8.435 13.1233Z" fill="%23df0523"/></svg>');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px 20px;
  border: rem(1) solid $color-white;
  border-bottom: rem(1) solid $color-border;
  border-radius: 0;
  appearance: none;
}

@use "sass:math";

html {
  &.-main-navi-open {
    width: 100%;
    height: 100dvh;
    overflow: hidden;

    @include mq(lg) {
      overflow: visible;
    }

    body {
      width: 100%;
      height: 100dvh;
      overflow: hidden;

      @include mq(lg) {
        overflow: visible;
      }

      .global-Pagetop {
        @include mq(lg) {
          display: none !important;
        }
      }
    }
  }
}

.local-MainNavi_Pc {
  position: relative;
  z-index: 110;
  display: none;

  @include mq(lg) {
    display: block;
    background-color: $color-white;
  }

  .local-MainNavi_Inner {
    @include mq(lg) {
      position: relative;
      z-index: 126;
      background-color: $color-white;
    }

    &.-is-Follow {
      @include mq(lg) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 121;
        width: 100%;
        box-shadow: 0 0 10px -6px rgb(0 0 0 / 60%);
      }
    }

    &.-is-FollowUp {
      @include mq(lg) {
        animation: animation-followup 500ms forwards;
      }
    }

    &.-is-FollowDown {
      @include mq(lg) {
        animation: animation-followdown 500ms forwards;
      }
    }
  }

  .local-MainNavi_Menu {
    @include mq(lg) {
      position: relative;
    }
  }

  .local-MainNavi_Main {
    @include mq(lg) {
      border-bottom: rem(1) solid $color-border;
    }
  }

  .local-MainNavi_MainInner {
    @include mq(lg) {
      max-width: calc($global-max-width + rem(56));
      padding: rem(3) rem(28) rem(0);
      margin: 0 auto;

      ul {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 0;
        margin: 0;

        li {
          margin-left: rem(15);
          font-size: rem(13);
          font-weight: 700;
          line-height: rem(19.5);
          color: $color-black;
          list-style: none;

          &:first-child {
            margin-left: 0;
          }

          a {
            display: flex;
            align-items: center;
            height: 100%;
            padding: rem(12) rem(4);
            color: $color-black;
            text-decoration: none;
            transition-timing-function: $transition-timing-function;
            transition-duration: $transition-duration;

            &.-is-active {
              position: relative;
              color: $color-black;

              &::before {
                position: absolute;
                top: auto;
                bottom: 0;
                left: 0;
                display: block;
                width: 100%;
                height: rem(2);
                content: "";
                background-color: $color-accent;
                animation: animation-borderwide 0.5s forwards;
              }
            }

            &.-is-select {
              position: relative;
              color: $color-accent;

              &::before {
                position: absolute;
                top: auto;
                bottom: 0;
                left: 0;
                display: block;
                width: 100%;
                height: rem(2);
                content: "";
                background-color: $color-accent;
                animation: animation-borderwide 0.5s forwards;
              }
            }

            @include hover-without-touch {
              color: $color-accent;
            }
          }
        }
      }
    }

    @include mq(xl) {
      ul {
        li {
          margin-left: rem(32);
          font-size: rem(15);
          line-height: rem(21);
        }
      }
    }
  }

  .local-MainNavi_Shadow {
    @include mq(lg) {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 120;
      display: none;
      width: 100%;
      height: 100dvh;
      background-color: rgb(0 0 0 / 40%);
    }
  }

  .local-MainNavi_Sub {
    @include mq(lg) {
      position: absolute;
      left: 0;
      display: none;
      width: 100%;
      overflow: auto;
      overscroll-behavior: contain;
    }
  }

  .local-MainNavi_SubInnerWrap {
    @include mq(lg) {
      background-color: $color-bg-area;
    }
  }

  .local-MainNavi_SubInner {
    @include mq(lg) {
      position: relative;
      max-width: calc($global-max-width + rem(56));
      padding: 0 rem(28);
      margin: 0 auto;
    }
  }

  .local-MainNavi_SubClose {
    @include mq(lg) {
      position: relative;
      right: rem(-7);
      z-index: 121;
      padding: 0;
      border-bottom: none;

      &::before {
        position: absolute;
        top: rem(34);
        right: 0;
        z-index: 121;
        display: block;
        width: rem(36);
        height: rem(36);
        cursor: pointer;
        content: "";
        background-image: $svg-x-black;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 20px 20px;
        opacity: 1;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
      }

      &::after {
        position: absolute;
        top: rem(34);
        right: 0;
        z-index: 121;
        display: block;
        width: rem(36);
        height: rem(36);
        cursor: pointer;
        content: "";
        background-image: $svg-x-white;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 20px 20px;
        opacity: 0;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
      }
    }

    @include hover-without-touch {
      @include mq(lg) {
        &::before {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }

        & > span {
          &::after {
            top: rem(34);
            right: 0;
            width: rem(36);
            height: rem(36);
          }
        }
      }
    }

    & > span {
      &::before {
        position: absolute;
        top: rem(34);
        right: 0;
        display: block;
        width: rem(36);
        height: rem(36);
        cursor: pointer;
        content: "";
        background-color: $color-gray8;
        border-radius: 50%;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
      }

      &::after {
        position: absolute;
        top: rem(50);
        right: rem(15);
        display: block;
        width: rem(0);
        height: rem(0);
        cursor: pointer;
        content: "";
        background-color: $color-gray1;
        border-radius: 50%;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;
      }

      & > span {
        @include mq(lg) {
          display: none;
        }
      }
    }
  }

  .local-MainNavi_SubMainAreaInner {
    @include mq(lg) {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .local-MainNavi_SubMainAreaItem {
    @include mq(lg) {
      align-self: stretch;

      &:nth-child(1) {
        flex-shrink: 0;
        width: 28.58%;
        padding: rem(40) rem(0) rem(54);
      }

      &:nth-child(2) {
        display: block;
        flex-grow: 1;
      }
    }
  }

  .local-MainNavi_SubMenuSubArea {
    @include mq(lg) {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
    }
  }

  .local-MainNavi_SubMenuSubAreaItem {
    @include mq(lg) {
      align-self: stretch;

      &:nth-child(1) {
        flex-grow: 1;
        padding: rem(40) rem(0) rem(54);
        border-left: rem(1) solid $color-border;
      }

      &:nth-child(2) {
        flex-shrink: 0;
        width: 59.76%;
        padding: rem(40) rem(0) rem(54);
        border-left: rem(1) solid $color-border;

        .local-MainNavi_SubMenuTitle {
          padding-right: rem(46);
        }
      }
    }
  }

  .local-MainNavi_SubMenuSubAreaItemInner {
    @include mq(lg) {
      opacity: 0;
      animation: animation-fadein 0.5s forwards;
    }
  }

  .local-MainNavi_SubMenuTitle {
    @include mq(lg) {
      display: block;
      padding-right: rem(28);
      padding-left: rem(20);
      font-size: rem(18);
      font-weight: 700;
      line-height: rem(30);
      color: $color-black;

      a {
        position: relative;
        display: inline-block;
        padding-right: rem(19);
        color: $color-black;
        text-decoration: none;
        transition-timing-function: $transition-timing-function;
        transition-duration: $transition-duration;

        &::before {
          position: absolute;
          top: 50%;
          right: rem(0);
          display: block;
          width: rem(14);
          height: rem(14);
          content: "";
          background-image: $svg-arw-gray2;
          background-size: 14px 14px;
          opacity: 1;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;
          transform: translate(0, -50%);
        }

        &::after {
          position: absolute;
          top: 50%;
          right: rem(0);
          display: block;
          width: rem(14);
          height: rem(14);
          content: "";
          background-image: $svg-arw-accent;
          background-size: 14px 14px;
          opacity: 0;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;
          transform: translate(0, -50%);
        }

        @include hover-without-touch {
          @include mq(lg) {
            color: $color-accent;

            &::before {
              opacity: 0;
            }

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .local-MainNavi_SubMenuExpandWrap {
    @include mq(lg) {
      margin-top: rem(16);
    }
  }

  .local-MainNavi_SubMenuExpand {
    @include mq(lg) {
      ul {
        padding: 0;
        margin: 0;

        li {
          font-size: rem(15);
          font-weight: 400;
          line-height: rem(20);
          list-style: none;

          a {
            display: block;
            padding: rem(12) rem(34) rem(12) rem(20);
            color: $color-black;
            text-decoration: none;
            transition-timing-function: $transition-timing-function;
            transition-duration: $transition-duration;

            &[data-main-navi-expand] {
              padding-right: rem(34);
              background-image: $svg-arw-gray2;
              background-repeat: no-repeat;
              background-position: right 16px center;
              background-size: 10px 10px;

              @include hover-without-touch {
                background-image: $svg-arw-accent;
              }
            }

            &.-is-select {
              color: $color-accent;
              background-color: $color-gray9;
              background-image: $svg-arw-accent;
            }

            @include hover-without-touch {
              color: $color-accent;
              background-color: $color-gray9;
            }
          }
        }
      }
    }
  }

  .local-MainNavi_SubMenuExpandTitle {
    @include mq(lg) {
      position: relative;
      padding: rem(12) 0 rem(12) rem(38);
      font-size: rem(13);
      font-weight: 700;
      line-height: rem(19.5);

      &::before {
        position: absolute;
        top: 50%;
        left: rem(20);
        display: block;
        width: rem(12);
        height: rem(3);
        content: "";
        background-color: $color-black;
        transform: translate(0, -50%);
      }
    }
  }

  .local-MainNavi_SubMenuIndexLink {
    font-size: rem(13);
    font-weight: 700;
    line-height: rem(19.5);

    a {
      display: block;
      padding: rem(12) rem(16) rem(12) rem(48);
      color: $color-black;
      text-decoration: none;
      background-image: $svg-index-list;
      background-repeat: no-repeat;
      background-position: left 20px center;
      background-size: 16px 11.21px;
      transition-timing-function: $transition-timing-function;
      transition-duration: $transition-duration;

      @include hover-without-touch {
        color: $color-accent;
        background-color: $color-gray3;
        background-image: $svg-index-list-accent;
      }
    }
  }
}

.local-MainNavi_Sp {
  @include mq(lg) {
    display: none;
  }

  .local-MainNavi_Title {
    font-size: rem(16);
    font-weight: 400;
    line-height: rem(24);
    color: $color-white;
    background-color: $color-gray4;
    border-bottom: rem(1) solid $color-border2;

    a {
      display: block;
      padding: rem(16);
      color: $color-white;
      text-decoration: none;
    }
  }

  .local-MainNavi_Menu {
    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
      }
    }

    & > ul {
      & > li {
        font-size: rem(16);
        font-weight: 400;
        line-height: rem(16);
        border-bottom: rem(1) solid $color-border2;

        & > a {
          position: relative;
          display: block;
          padding: rem(18) rem(16);
          color: $color-white;
          text-decoration: none;
          background-color: $color-gray5;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;

          &[data-main-navi-sp-expand] {
            &::after {
              position: absolute;
              top: 50%;
              right: rem(14);
              display: block;
              width: rem(16);
              height: rem(16);
              content: "";
              background-image: $svg-arw-gray3;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 16px 16px;
              transition-timing-function: $transition-timing-function;
              transition-duration: $transition-duration;
              transform: translate(0, -50%) rotate(90deg);
            }

            &.-is-select {
              &::after {
                transform: translate(0, -50%) rotate(-90deg);
              }
            }
          }
        }

        & > ul {
          display: none;
          padding: 0 rem(16);
          background-color: $color-gray6;

          & > li {
            background-color: $color-gray6;
            border-bottom: rem(1) solid $color-border2;

            &:last-child {
              border-bottom: none;
            }

            &.-is-top {
              border-bottom: none;

              & > a {
                padding: rem(18) rem(16) rem(18) rem(16);
                background-color: $color-gray7;

                &::before {
                  display: none;
                }
              }
            }

            & > a {
              position: relative;
              display: block;
              padding: rem(18) rem(16) rem(18) rem(30);
              margin: 0 rem(-16);
              color: $color-white;
              text-decoration: none;
              background-color: $color-gray6;

              &::before {
                position: absolute;
                top: 50%;
                left: rem(18);
                display: block;
                width: 0;
                height: 0;
                content: "";
                border-style: solid;
                border-top: 5px solid transparent;
                border-right: 0;
                border-bottom: 5px solid transparent;
                border-left: 8px solid $color-accent;
                transition-timing-function: $transition-timing-function;
                transition-duration: $transition-duration;
                transform: translate(0, -50%);
              }

              &.-is-select {
                &::before {
                  transform: translate(0, -50%) rotate(90deg);
                }
              }
            }

            & > ul {
              display: none;
              padding-bottom: rem(10);

              & > li {
                margin-top: rem(8);
                font-size: rem(15);
                line-height: rem(15);

                &:first-child {
                  margin-top: 0;
                }

                & > a {
                  display: block;
                  padding: rem(9) rem(16) rem(9) rem(30);
                  margin: 0 rem(-16);
                  color: $color-white;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.local-MainNavi_LinePc {
  display: none;

  @include mq(lg) {
    display: block;
  }
}

.local-MainNavi_LinePcInner {
  @include mq(lg) {
    max-width: calc($global-max-width + rem(56));
    height: 100%;
    padding: rem(3) rem(0) rem(0) rem(28);
    margin: 0 auto;

    ul {
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      justify-content: flex-start;
      height: 100%;
      padding: 0;
      margin: 0;

      li {
        margin-left: rem(15);
        font-size: rem(13);
        font-weight: 700;
        line-height: rem(19.5);
        color: $color-black;
        list-style: none;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          height: 100%;
          padding: rem(12) rem(4);
          color: $color-black;
          text-decoration: none;
          transition-timing-function: $transition-timing-function;
          transition-duration: $transition-duration;

          &.-is-active {
            position: relative;
            color: $color-black;

            &::before {
              position: absolute;
              top: auto;
              bottom: 0;
              left: 0;
              display: block;
              width: 100%;
              height: rem(2);
              content: "";
              background-color: $color-accent;
              animation: animation-borderwide 0.5s forwards;
            }
          }

          &.-is-select {
            position: relative;
            color: $color-accent;

            &::before {
              position: absolute;
              top: auto;
              bottom: 0;
              left: 0;
              display: block;
              width: 100%;
              height: rem(2);
              content: "";
              background-color: $color-accent;
              animation: animation-borderwide 0.5s forwards;
            }
          }

          @include hover-without-touch {
            color: $color-accent;
          }
        }
      }
    }
  }

  @include mq(xl) {
    ul {
      li {
        margin-left: rem(32);
        font-size: rem(15);
        line-height: rem(21);
      }
    }
  }
}
